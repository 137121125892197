import axios from "axios";
import { setInvoicingSteps } from "../../store/actions/navigation";
import { useSelector } from "react-redux";
import { selectInvoicingSteps } from "../../store/selectors/navigation";

const key = process.env.REACT_APP_API_KEY;
const endpoint = `invoicing?code=${key}`;
const url = `/api/${endpoint}`;

export const adminInit = () => {
  return {
    type: "invoicing/init",
  };
};

const storeContractsList = (list) => {
  return {
    type: "invoicing/storeContractsList",
    payload: list,
  };
};
export const getContractsList = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`${url}&&type=contractsList`);
    dispatch(storeContractsList(response.data));
  };
};

export const setSelectedContract = (id) => {
  return {
    type: "invoicing/setSelectedContract",
    payload: id,
  };
};

const storeContractDetails = (details) => {
  return {
    type: "invoicing/storeContractDetails",
    payload: details,
  };
};

export const getContractDetails = (id) => {
  return async (dispatch, getState) => {
    let response = await axios.get(`${url}&&type=contractDetails&&id=${id}`);
    // console.log(response.data.invoices[0].description.split("\n"));
    dispatch(storeContractDetails(response.data));
  };
};

export const nullContractDetails = () => {
  console.log("null");
  return {
    type: "invoicing/nullContractDetails",
  };
};
export const storeUnconifrmed = (contract) => {
  return {
    type: "invoicing/storeUnconifrmed",
    payload: contract,
  };
};
export const nullContractToConfirm = () => {
  return {
    type: "invoicing/nullContractToConfirm",
  };
};
export const updateUnconifrmed = (contract) => {
  console.log(contract);
  return async (dispatch, getstate) => {
    let response = await axios.post(`${url}&&type=updateUnconifrmed`, {
      contract,
    });
    dispatch(nullContractToConfirm());
    dispatch(getUnconifrmedContracts());
  };
  // return {
  //   type: "invoicing/updateUnconifrmed",
  //   payload: contract,
  // };
};
export const getUnconifrmedContracts = () => {
  return async (dispatch, getstate) => {
    let response = await axios.get(`${url}&&type=changedContracts`);
    dispatch(storeUnconifrmed(response.data));
  };
};

export const setOrderlineEndDateEdit = (qlineId) => {
  return {
    type: "invoicing/setOrderlineEndDateEdit",
    payload: qlineId,
  };
};

export const storeNewInvoices = (invoices) => {
  console.log(invoices);
  return {
    type: "invoicing/storeNewInvoices",
    payload: invoices,
  };
};

export const generateInvoices = (invoicingSteps) => {
  return async (dispatch, getState) => {
    if (invoicingSteps.invoicingDate) {
      dispatch(
        setInvoicingSteps({
          ...getState().navigation.invoicing,
          generatingInvoices: true,
        })
      );

      console.log("get the order - busy");

      let response = await axios.post(`${url}&&type=getTheOrder`, {});

      dispatch(
        setInvoicingSteps({
          ...getState().navigation.invoicing,
          linesOrder: response.data,
        })
      );

      console.log("clearing invoices - busy");
      response = await axios.post(`${url}&&type=clearInvoices`, {});
      if (response.data === "True") {
        console.log("clearing invoices - done");
      } else {
        console.log("clearing invoices - fail");
        return;
      }

      if (invoicingSteps.generalInvoices) {
        console.log("General - creating regular invoice lines - busy");
        response = await axios.post(
          `${url}&&type=generalNewInvoiceLinesRegular`,
          {
            date: invoicingSteps.invoicingDate,
          }
        );
        if (response.data === "True") {
          console.log("General - creating regular invoice lines - done");
        } else {
          console.log("General - creating regular invoice lines - fail");
          return;
        }
      }

      if (invoicingSteps.partnerFees) {
        console.log("Partner fees - creating invoice lines - busy");
        response = await axios.post(`${url}&&type=feesNewInvoiceLines`, {
          date: invoicingSteps.invoicingDate,
        });
        if (response.data === "True") {
          console.log("Partner fees - creating invoice lines - done");
        } else {
          console.log("Partner fees - creating invoice lines - fail");
          return;
        }
      }

      if (invoicingSteps.generalInvoices) {
        console.log("General - creating credit invoice lines - busy");
        response = await axios.post(
          `${url}&&type=generalNewInvoiceLinesCredit`,
          {
            date: invoicingSteps.invoicingDate,
          }
        );
        if (response.data === "True") {
          console.log("General - creating credit invoice lines - done");
        } else {
          console.log("General - creating credit invoice lines - fail");
          return;
        }
      }

      if (invoicingSteps.generalInvoices) {
        console.log("General - correcting end dates - busy");
        response = await axios.post(`${url}&&type=generalCorrectingEndDates`, {
          date: invoicingSteps.invoicingDate,
        });
        if (response.data === "True") {
          console.log("General - correcting end dates - done");
        } else {
          console.log("General - correcting end dates - fail");
          return;
        }
      }

      if (invoicingSteps.generalInvoices) {
        console.log("General - setting quantities - busy");
        response = await axios.post(`${url}&&type=generalSettingQuantities`, {
          date: invoicingSteps.invoicingDate,
        });
        if (response.data === "True") {
          console.log("General - setting quantities - done");
        } else {
          console.log("General - setting quantities - fail");
          return;
        }
      }

      let generalInvoices = [];
      let feesInvoices = [];
      let usageInvoices = [];

      if (invoicingSteps.generalInvoices) {
        console.log("General - creating invoices - busy");
        response = await axios.post(`${url}&&type=generalCreatingInvoices`, {
          date: invoicingSteps.invoicingDate,
        });
        if (response.data !== "False") {
          generalInvoices = response.data;
        } else {
          console.log("General - creating invoices - fail");
          return;
        }
      }
      if (invoicingSteps.partnerFees) {
        console.log("Partner fees - creating invoices - busy");
        response = await axios.post(`${url}&&type=feesCreatingInvoices`, {
          date: invoicingSteps.invoicingDate,
        });
        if (response.data !== "False") {
          feesInvoices = response.data;
        } else {
          console.log("General - creating invoices - fail");
          return;
        }
      }
      console.log("dups");

      dispatch(
        storeNewInvoices([
          ...generalInvoices,
          ...feesInvoices,
          ...usageInvoices,
        ])
      );
      dispatch(
        setInvoicingSteps({
          ...getState().navigation.invoicing,
          generatingInvoices: false,
          allInvoicesIds: [
            ...generalInvoices,
            ...feesInvoices,
            ...usageInvoices,
          ].map((invoice) => invoice.id),
        })
      );
    }
  };
};
