import axios from "axios";
const key = process.env.REACT_APP_API_KEY;

export const storeOffer = (data) => {
  return {
    type: "camp/storeCamp",
    payload: data,
  };
};
export const cleanCamp = () => {
  return {
    type: "camp/cleanCamp",
  };
};

export const getOffer = (req) => {
  return async (dispatch, getState) => {
    let response = await axios.post(
      `/api/campaign?code=${key}&&type=getOffer`,
      { ...req }
    );
    dispatch(storeOffer(response.data));
  };
};
export const confirmOffer = (req) => {
  return async (dispatch, getState) => {
    let response = await axios.post(
      `/api/campaign?code=${key}&&type=confirmOffer`,
      { ...req }
    );
    dispatch(storeOffer({ ...getState().camp, completed: response.data }));
  };
};
