const initialState = "init";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "camp/cleanCamp":
      return initialState;
    case "camp/storeCamp":
      return action.payload;
    default:
      return state;
  }
}
