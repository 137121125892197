import React, { useRef, memo } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { selectInvoicingSteps } from "../store/selectors/navigation";
import NewTable from "../components/newTable/newTable";
import copyIco from "../copyIco.svg";
import netherlands from "../netherlands.png";
import germany from "../germany.png";
import unitedK from "../united-kingdom.png";
import { AssetsCheckbox3 } from "../components/assetsInputs";
import { setInvoicingSteps } from "../store/actions/navigation";

const PanelComponent = (props) => {
  const headerRef = useRef();
  const dispatch = useDispatch();

  const invoicingSteps = useSelector(selectInvoicingSteps);
  const dataLine = (desc, value, onClick) => {
    return (
      <div className="dasLine">
        <div className="dasDesc">{desc}</div>
        <div className="dasValue" onClick={onClick}>
          {value}
        </div>
      </div>
    );
  };
  const stringCut = (string, stringLength) => {
    const allParts = string.split(" ");
    let newIndex = 0;
    let newArr = [];
    for (let part of allParts) {
      if (
        `${newArr[newIndex] ? newArr[newIndex] : ""}${part} `.length >
        stringLength
      ) {
        newIndex++;
      }
      newArr[newIndex] = `${newArr[newIndex] ? newArr[newIndex] : ""}${part} `;
    }
    return newArr;
  };
  const { ref, inView } = useInView({
    threshold: 0,
  });
  if (props.invoicePreSel) {
    let iLinesTotal = 0;
    for (let line of props.invoicePreSel.invoiceLines) {
      iLinesTotal =
        iLinesTotal +
        parseFloat(
          line.item_quantity && line.item_price
            ? parseFloat(
                Math.round(line.item_quantity * line.item_price * 100) / 100
              ).toFixed(2)
            : 0
        );
    }
    console.log(!props.invoicePreSel.agent ? props.invoicePreSel : "");
    return (
      <div
        className={
          invoicingSteps.includedInvoicesIds.includes(props.invoicePreSel.id)
            ? "invoicePreselectPanel"
            : "invoicePreselectPanel invoicePanelDisabled"
        }
      >
        <div className="invoicePreselectDescA" ref={ref}>
          {["Regular", "Credit"].includes(
            props.invoicePreSel.invoiceLines[0].invoice_type
          )
            ? stringCut(props.invoicePreSel.customer, 34).map((line, i) =>
                dataLine(i === 0 ? "Customer:" : "", line)
              )
            : stringCut(props.invoicePreSel.agent, 34).map((line, i) =>
                dataLine(i === 0 ? "Agent:" : "", line)
              )}
          {stringCut(`${props.invoicePreSel.twinfield_id}`, 34).map((line, i) =>
            dataLine(i === 0 ? "Twinfield id:" : "", line)
          )}
          {stringCut(
            `${props.invoicePreSel.service_id} - ${props.invoicePreSel.user}`,
            33
          ).map((line, i) => dataLine(i === 0 ? "Service:" : "", line))}
          {stringCut(
            `${
              props.invoicePreSel.description
                .split("\n")
                .reverse()[0]
                .split(" ")[2]
            } - ${
              props.invoicePreSel.description
                .split("\n")
                .reverse()[0]
                .split(" ")[4]
            }`,
            34
          ).map((line, i) => dataLine(i === 0 ? "Period:" : "", line))}
          {stringCut(`${props.invoicePreSel.contract_id}`, 34).map((line, i) =>
            dataLine(i === 0 ? "Contract id:" : "", line)
          )}
        </div>
        <div className="invoicePreselectLines" ref={headerRef}>
          {inView ? (
            <NewTable
              tit={"invoicesPreselect"}
              tabletype="small"
              className="histTable"
              data={[
                ...props.invoicePreSel.invoiceLines
                  .map((line) => {
                    return {
                      ...line,
                      item_quantity: line.item_quantity
                        ? parseFloat(line.item_quantity).toFixed(2)
                        : null,
                      item_price: line.item_price
                        ? parseFloat(line.item_price).toFixed(2)
                        : null,
                      total:
                        line.item_quantity && line.item_price
                          ? parseFloat(
                              Math.round(
                                line.item_quantity * line.item_price * 100
                              ) / 100
                            ).toFixed(2)
                          : null,
                    };
                  })
                  .sort((a, b) => {
                    return (
                      parseInt(`${a.item_id}`.slice(-3)),
                      invoicingSteps.linesOrder.find(
                        (line) =>
                          parseInt(line.id) ===
                          parseInt(`${a.item_id}`.slice(-3))
                      ).order -
                        invoicingSteps.linesOrder.find(
                          (line) =>
                            parseInt(line.id) ===
                            parseInt(`${b.item_id}`.slice(-3))
                        ).order
                    );
                  }),
                {
                  total: parseFloat(
                    Math.round(iLinesTotal * 100) / 100
                  ).toFixed(2),
                },
              ]}
              width={headerRef.current ? headerRef.current.clientWidth : 100}
            />
          ) : (
            ""
          )}
        </div>
        <AssetsCheckbox3
          value={invoicingSteps.includedInvoicesIds.includes(
            props.invoicePreSel.id
          )}
          onChangeFunc={() => {
            if (
              !invoicingSteps.includedInvoicesIds.includes(
                props.invoicePreSel.id
              )
            ) {
              dispatch(
                setInvoicingSteps({
                  ...invoicingSteps,
                  includedInvoicesIds: [
                    ...invoicingSteps.includedInvoicesIds,
                    props.invoicePreSel.id,
                  ],
                })
              );
            } else {
              dispatch(
                setInvoicingSteps({
                  ...invoicingSteps,
                  includedInvoicesIds: [
                    ...invoicingSteps.includedInvoicesIds.filter(
                      (id) => id !== props.invoicePreSel.id
                    ),
                  ],
                })
              );
            }
          }}
        />
      </div>
    );
  } else if (props.customer) {
    let invTotal = 0;
    for (let subInv of props.subInvoices) {
      let subTotal = 0;
      for (let total of subInv.invoiceLines.map((line) =>
        line.item_quantity && line.item_price
          ? parseFloat(
              parseFloat(
                Math.round(line.item_quantity * line.item_price * 100) / 100
              ).toFixed(2)
            )
          : null
      )) {
        subTotal = subTotal + total;
      }
      invTotal = invTotal + subTotal;
    }
    const copyStyle = (itemClass, itemId) => {
      document.getElementById(
        itemId
      ).className = `${itemClass} invoicingCopied`;
      setTimeout(
        () => (document.getElementById(itemId).className = `${itemClass}`),
        750
      );
    };
    return (
      <div className={"invoiceGeneralPanel"}>
        <div className="invoiceGeneralHeader">
          <div className={"invoicePreselectDescB"}>
            <div className={"invoicePreselectDesc1stLine"}>
              <div className="dasLine">
                <img
                  alt=""
                  src={
                    props.language === "nl-NL"
                      ? netherlands
                      : props.language === "en-GB"
                      ? unitedK
                      : props.language === "de-DE"
                      ? germany
                      : ""
                  }
                  className="invoicingFlag"
                  height={"20px"}
                />
                {props.agent ? props.agent : props.customer}
              </div>
              <div className="dasLine">{`Twinfield id: ${props.twinfieldId}`}</div>
            </div>
          </div>
          <div className={"invoicePreselectDescB"}>
            <div className={"invoicePreselectDesc1stLine"}>
              <div className="dasLine" ref={ref} id="generalDesc">
                {props.generalDesc}
                {props.generalDesc ? (
                  <img
                    alt=""
                    src={copyIco}
                    height={"16px"}
                    onClick={(e) => {
                      copyStyle("dasLine", "generalDesc");
                      navigator.clipboard.writeText(props.generalDesc);
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
              {props.generalDesc ? (
                <div className="dasLine">{`Total: ${invTotal.toFixed(2)}`}</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {props.subInvoices.map((subInv) => {
          let subTotal = 0;
          for (let total of subInv.invoiceLines.map((line) =>
            line.item_quantity && line.item_price
              ? parseFloat(
                  parseFloat(
                    Math.round(line.item_quantity * line.item_price * 100) / 100
                  ).toFixed(2)
                )
              : null
          )) {
            subTotal = subTotal + total;
          }
          return (
            <div className={"subInvoicePanel"}>
              <div className={"invoicePreselectDescA"} id={subInv.description}>
                {subInv.description.split("\n").map((descLine, index) => {
                  if (index === subInv.description.split("\n").length - 1) {
                    return (
                      <p>
                        {descLine}
                        <img
                          src={copyIco}
                          height={"16px"}
                          alt=""
                          onClick={(e) => {
                            copyStyle(
                              "invoicePreselectDescA",
                              subInv.description
                            );
                            navigator.clipboard.writeText(subInv.description);
                          }}
                        />
                      </p>
                    );
                  } else {
                    return <p>{descLine}</p>;
                  }
                })}
              </div>
              <div className="invoicePreselectLines" ref={headerRef}>
                <NewTable
                  tit={"invoicesPreselect"}
                  tabletype="small"
                  className="histTable"
                  data={[
                    ...subInv.invoiceLines
                      .map((line) => {
                        return {
                          ...line,
                          copy: (
                            <img
                              src={copyIco}
                              height={"16px"}
                              alt=""
                              onClick={(e) => {
                                copyStyle(
                                  "MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft MuiTableCell-sizeMedium elipsisRow css-c4wihh-MuiTableCell-root",
                                  `${line.qt_line_id}${line.item_description}`
                                );
                                navigator.clipboard.writeText(
                                  line.item_description
                                );
                              }}
                            />
                          ),
                          item_quantity: line.item_quantity
                            ? parseFloat(line.item_quantity).toFixed(2)
                            : null,
                          item_price: line.item_price
                            ? parseFloat(line.item_price).toFixed(2)
                            : null,
                          total:
                            line.item_quantity && line.item_price
                              ? parseFloat(
                                  Math.round(
                                    line.item_quantity * line.item_price * 100
                                  ) / 100
                                ).toFixed(2)
                              : null,
                        };
                      })
                      .sort((a, b) => {
                        return (
                          parseInt(`${a.item_id}`.slice(-3)),
                          invoicingSteps.linesOrder.find(
                            (line) =>
                              parseInt(line.id) ===
                              parseInt(`${a.item_id}`.slice(-3))
                          ).order -
                            invoicingSteps.linesOrder.find(
                              (line) =>
                                parseInt(line.id) ===
                                parseInt(`${b.item_id}`.slice(-3))
                            ).order
                        );
                      }),
                    // {
                    //   total: parseFloat(
                    //     Math.round(iLinesTotal * 100) / 100
                    //   ).toFixed(2),
                    // },
                    {
                      total: subTotal.toFixed(2),
                    },
                  ]}
                  width={
                    headerRef.current ? headerRef.current.clientWidth : 100
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return <div></div>;
  }
};
export const MemoPanelComponent = memo(PanelComponent);
